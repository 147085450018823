import { template as template_e20bcbf9b88541eaa4ceb3bd88d54290 } from "@ember/template-compiler";
const FKLabel = template_e20bcbf9b88541eaa4ceb3bd88d54290(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
