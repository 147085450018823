import { template as template_5265f4c8552b44bb949bad1e49fc20dd } from "@ember/template-compiler";
const FKText = template_5265f4c8552b44bb949bad1e49fc20dd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
