import { template as template_45c5253b6f3f43f1b170410655954b06 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_45c5253b6f3f43f1b170410655954b06(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
