import { template as template_479837e48c294a298372b8f01f028447 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_479837e48c294a298372b8f01f028447(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
