import { template as template_dae4d72c693047fb84251413e502a907 } from "@ember/template-compiler";
const WelcomeHeader = template_dae4d72c693047fb84251413e502a907(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
